@tailwind base;
@tailwind components;
@tailwind utilities;
 
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
 
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
 
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
 
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
 
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
 
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
 
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
 
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
 
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

/* Connect Wallet */
.wallet-adapter-button.wallet-adapter-button-trigger {
  @apply bg-secondary text-black hover:text-white  hover:bg-primary dark:bg-primary dark:hover:bg-secondary !important;
  border: 1px solid black !important;
}


.markdown > h1 {
  @apply mb-2 text-lg;
}

.markdown > h2 {
  @apply mb-2 text-lg;
}

.markdown > p {
  @apply mb-2;
}

.markdown ul {
  @apply mb-4;
}

.markdown a {
  @apply underline break-all transition-all duration-300 hover:no-underline;
}

.markdown li {
  @apply mb-2 ml-6 font-normal list-disc;
}

.markdown pre {
  @apply whitespace-pre-wrap;
}

.markdown code {
  @apply block mb-2 text-sm;
}

.markdown table {
  margin: 10px 0;
  border: 1px solid #ccc;
  @apply border;
  border-collapse: collapse;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


@keyframes pulse {
  0% {
    background-color: rgba(150, 150, 150, 0.5);
  }
  50% {
    background-color: rgba(27, 27, 27, 0.8);
  }
  100% {
    background-color: rgba(187, 187, 187, 0.5);
  }
}

.animate-pulse-bg {
  animation: pulse 0.7s infinite ease-in-out;
}